.btn {
  display: inline-block;
  overflow: hidden;
  position: relative;
  background-color: rgb(4 120 87);
  text-decoration: none;
  z-index: 0;
  color: #FFFFFF;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  -webkit-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  cursor: pointer;
}

.btn:after,
.btn:before {
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.btn--border {
  border: solid 1px rgb(4 120 87);
}

.btn--sm{
  padding: 10px 12px;
}

.btn--md{
  padding: 12px 16px;
}

.btn--lg{
  padding: 14px 20px;
}

.btn--1:after {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: #FFFFFF;
}

.btn--1:hover {
  color: rgb(4 120 87);
}

.btn--1:hover:after {
  height: 100%;
}

.btn--2:hover {
  color: rgb(4 120 87);
}

.btn--2:after {
  width: 100%;
  height: 100%;
  content: "";
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
}

.btn--2:hover:after {
  transform: scale(1);
}

.btn--3 {
  overflow: hidden;
}

.btn--3:hover {
  color: rgb(4 120 87);
}

.btn--3:after {
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background-color: #FFFFFF;
  position: absolute;
  top: 150%;
  left: 00%;
  z-index: -1;
  transform: skewY(20deg);
}

.btn--3:before {
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background-color: #FFFFFF;
  position: absolute;
  bottom: 150%;
  left: 00%;
  z-index: -1;
  transform: skewY(20deg);
}

.btn--3:hover:after {
  top: 48%;
}

.btn--3:hover:before {
  bottom: 48%;
}

.btn--4:hover {
  box-shadow: 0 0 0px 30px #FFFFFF inset;
  color: rgb(4 120 87);
}