.categories-page{
  .categories-page__title{
    .container{
      padding: 0;
    }

    .heading-section__title{
      margin-top: 4px;
    }
  }

  .categories-page__bottom{
    .container{
      padding: 0;
    }
  }
}