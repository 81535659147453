.introduction-page{
  .heading-section{
    .container{
      padding: 0;
    }

    .heading-section__title{
      margin-top: 4px;
    }
  }
}