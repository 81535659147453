.sharing{
  position: fixed;
  z-index: 999999;
  right: 10px;
  bottom: 50px;
}

.sharing__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sharing__item{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    opacity: 0.8;
    transition: all 0.3s;
  }
  &::after{
    content: "";
    position: absolute;
    border: 1px solid var(--color);
    background-color: var(--color);
    z-index: -1;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    border-radius: 50%;
    animation: zoom 2s linear infinite;
  }
}

@keyframes zoom {
  0% {
    transform: scale(.5);
    opacity: 0;
  }
  50% {
      opacity: 1;
      transform: scale(1.02);
  }
  100% {
      opacity: 0;
      transform: scale(0.5);
  }
}

.tooltip-text {
  position: absolute;
  right: 55px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  width: var(--width);
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s;
  opacity: 0;
  display: none;
  p{
    margin-bottom: 0;
    font-size: 13px;
    color: #fff;
    line-height: 26px;
  }
  &::after{
    content: "";
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #000;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    margin: auto;
    -ms-transition: all 200ms linear;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
}

.sharing__item:hover{
  .tooltip-text{
    display: block;
    opacity: 1;
    transition: all 0.3s;
  }
}
