.container{
  max-width: var(--grid-container-width);
  margin: auto;
  padding-left: var(--grid-container-gap);
  padding-right: var(--grid-container-gap);
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--grid-col-gap) / -2);
  margin-right: calc(var(--grid-col-gap) / -2);
  margin-top: calc(var(--grid-col-gap-horizontal) * -1);
}

.col{
  width: calc(var(--col-xs) / var(--grid) * 100%);
  padding-top: var(--grid-col-gap-horizontal);
}

@media (min-width: 768px) {
  .col {
    width: calc(var(--col-sm) / var(--grid) * 100%);
  }
}

@media (min-width: 992px) {
  .col {
    width: calc(var(--col-md) / var(--grid) * 100%);
    padding-left: calc(var(--grid-col-gap-vertical) / 2);
    padding-right: calc(var(--grid-col-gap-vertical) / 2);
  }
}

@media (min-width: 1100px) {
  .col {
    width: calc(var(--col-lg) / var(--grid) * 100%);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container{
    padding-left: var(--grid-container-gap);
    padding-right: var(--grid-container-gap);
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container{
    padding-left: var(--grid-container-gap);
    padding-right: var(--grid-container-gap);
  }
}